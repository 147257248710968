    .cartProductCardContainer {
    /* border: 1px solid red; */
    font-size: larger;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.each-product {
    /* border: 2px solid blue; */
    padding-bottom: 25px;
    border-bottom: 1px solid black;
}

.userInteraction{
    /* border: 2px solid black; */
    width: fit-content;
    width: 100%;
}

.update-remove{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 5px;
    margin-top: 10px;
}

.updateCartButton {
    color:black;
    background-color: white;
    border-radius: 50px;
    padding: 6px 20px;
    font-size: 15px;
    margin-top: 10px;
    cursor: pointer;
}

.removeCartButton {
    background-color: black;
    color: white;
    border-radius: 50px;
    padding: 6px 40px;
    font-size: 15px;
    margin-top: 10px;
    cursor: pointer;
}

.purchaseButtonContainer{
    display: flex;
    justify-content: center;

}

.purchaseButton{
    color:black;
    background-color: white;
    border-radius: 50px;
    padding: 3px 20px;
    font-size: 15px;
    margin-top: 10px;
    cursor: pointer;
}

.cart_product_name{
    font-weight:bolder;
    margin-left: 5px;
}

.quantityxprice{
    font-weight:bolder;
    margin-left: 5px;
}

.quantitySelector{
    font-weight: bold;
    width: 60px;
}

.totalPrice,
.userCartHeading {
    text-align: center;
    font-weight: bolder;
}

.cartImg{
    width: 300px;
    height: 200px;
    object-fit: cover;
    margin-bottom: 10px;
}

.purchaseMsg{
    text-align: center;
}
