@media screen and (min-width: 861px) {
    #update-product-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }

    #update-product-form>label {
        width: 50%;
    }

    #update-product-form>label>* {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 100%;
    }

    #update-product-form>label>.product_price {
        width: 30%;
        border: 1px solid black;
    }

    #update-product-form {
        width: 90%;
    }

    .update_submit_button {
        text-align: center;
    }

    .update_submit_button>button {
        border: none;
        background-color: black;
        color: white;
        border-radius: 15px;
        padding: 5px 15px;
    }

    div>.product_img_update {
        width: 80px;
    }
}

@media screen and (max-width: 860px) {
    #update-product-form {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 15px;
        height: 100%;
    }

    .updateProductH1{
        width: 100%;
        text-align: center;
    }

    #update-product-form>label {
        width: 90%;
    }
    #update-product-form>label>.product_name {
        width: 100%;
    }

    #update-product-form>label>* {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 100%;
    }

    #update-product-form>label>.product_price {
        width: 30%;
        border: 1px solid black;
    }

    #update-product-form {
        width: 90%;
    }

    .update_submit_button {
        text-align: center;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .update_submit_button>button {
        border: none;
        background-color: black;
        color: white;
        border-radius: 15px;
        padding: 5px 15px;
    }

    div>.product_img_update {
        width: 80px;
    }
}
