@media screen and (min-width: 861px){
  .profile-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  right: 100px;
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 0.755);
  width: 150px;
}
.loginButtonModal > button {
  color: black;
  border: none;
  background-color: transparent;
}

.logo:hover {
  cursor: pointer;
}
.signupButtonModal > button {
  color: black;
  border: none;
  background-color: transparent;
}

.profileButtonContainer{
  position: relative;
}
.profile-dropdown-logged-out {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  right: 100px;
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 0.755);
  width: 150px;
}
.hidden{
  display: none;
}

.socialsAndProfile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.userButtonProfileThingButton {
  width: 80%;
  margin-bottom: 5px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: black;
}

.usernameAndEmail{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.usernameAndEmail > p {
  text-align: center;
  margin: auto;
}

.hidden {
  display: none;
}

/* styles.css */
body {
  transition: background-color 0.3s, color 0.3s;
}

body.light {
  background-color: #fff;
  color: #1d1d1d;
}

body.dark {
  background-color: #202124;
  color: #e0e0e0;
}


.navBar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100px;
  width: 100%;
  font-size: large;
  border-bottom: 1px solid black;
}

.newProduct {
  display: flex;
  justify-content: start;
  width: fit-content;
}

.UserButton {
  position: fixed;
}

#userButtonProfileThing {
  margin-right: 100px;
  background-color: transparent;
  border: none;
  padding: .5rem;
  color: black;
}
#userButtonProfileThingLoggedOut {
  margin-right: 100px;
  background-color: transparent;
  border: none;
  padding: .5rem;
  color: black;
}

.fa-lg {
  font-size: 2.5rem;
}

.fa {
  font-size: 1rem;
}


li {
  list-style-type: none;
}

.your_profile_button {
  text-decoration: none;
  color: black;
}

.NewProductListing {
  text-decoration: none;
  color: black;
  font-size: 1.75rem;
}

.socials>button {
  font-size: large;
  border-radius: 15px;
  padding: 5px 15px;
  background-color: transparent;
  cursor: pointer;
}

.logo {
  width: 150px;
  object-fit: cover;
}

.HomeButton {
  object-fit: cover;
  display: flex;
  height: 100%;
}

.socials {
  display: flex;
  justify-content: center;
  width: 100%;
}

.socialsAndProfile > .socials{
  width: fit-content;
}

.cart_button {
  text-decoration: none;
  color: black;
  position: relative;
  bottom: 35px;
}

.cartAmount {
  position: relative;
  bottom: 15px;
  left: 20px;
}

.searchBarContainer {
  width: 37%;
  text-align: center;
}

.searchBar {
  width: 91%;
  border: none;
  flex-grow: 1;
  border-radius: 3px;
  padding: 5px;
}

.searchBar:focus {
  outline: none;
}

.searchSubmit {
  height: 100%;
  background-color: white;
  border: none;
  position: relative;
  right: 25px;
  border-radius: 3px;
}
.searchBarWithButton {
  width: 100%;
}
}

@media screen and (min-width: 861px){
  .profile-dropdown {
    position: absolute;
    left: 0px;
  }
  .profile-dropdown-logged-out{
    position: absolute;
    left: 0px;
  }
  .profileButtonContainer{
  position: relative;
}
}

@media screen and (max-width: 1035px){
  .socialsAndProfile > .socials{
    width: fit-content;
  }
}

@media screen and (min-width: 1920px) {
  .profile-dropdown {
    right: 350px;
  }
}

@media screen and (max-width: 860px) {
  .navBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid black;
    padding-bottom: 15px;
  }
  .signupButtonModal > button {
    color: black;
    border: none;
    background-color: transparent;
  }
  .logo{
    height: 100px;
  }
  .socialsAndProfile{
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 10px;
  }
  .socialsAndProfile > div{
    width: 50px;
  }
  .socials > button {
    width: 60px;
  }
  .cart_button {
    text-decoration: none;
    color: black;
    position: relative;
    bottom: 25px;
  }
  .cartAmount {
    position: relative;
    bottom: 10px;
    left: 20px;
  }
  .userButtonProfileThingButton {
    width: 80%;
    margin-bottom: 5px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: black;
  }

  #userButtonProfileThingLoggedOut{
    display: flex;
    position: absolute;
    top: 160px;
    color: black;
  }
  #userButtonProfileThing{
    display: flex;
    position: absolute;
    top: 200px;
    color: black;
  }

  .loginButtonModal > button {
    color: black;
    border: none;
    background-color: transparent;
  }

.NewProductListing {
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
}
.socials>button {
  color: black;
  border-radius: 15px;
  background-color: transparent;
  cursor: pointer;
}
.socialsAndProfile > .profileButtonContainer{
  display: flex;
  justify-content: center;
}

.profileButtonContainer > button{
  background-color: transparent;
  border: none;
  position: absolute;
  top: 240px;
}
.profile-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  right: 80px;
  top: 220px;
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 0.755);
  width: 150px;
  padding: 10px 0px;
  gap: 5px;
}
.profile-dropdown-logged-out {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  right: 70px;
  top: 200px;
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 0.755);
  width: 150px;
  padding: 10px 0px;
  gap: 5px;
}
.searchBarContainer {
  text-align: center;
  display:flex;
  justify-content: center;
  width: 80%;
}

.searchContainer{
  width: 100%;
  margin-left: 20px;
}

.searchBar {
  width: 80%;
  border: none;
  flex-grow: 1;
  border-radius: 3px;
  padding: 5px;
}

.searchBar:focus {
  outline: none;
}
.searchSubmit {
  height: 100%;
  background-color: white;
  border: none;
  position: relative;
  right: 25px;
  border-radius: 3px;
  color: black;
}
}

.hidden {
  display: none;
}

.your_profile_button {
  text-decoration: none;
  color: black;
}


