/* TODO Add site wide styles */
#root {
    margin: 0px 2.5rem;
    font-family: 'Neuton', serif;
}

.errors {
    color: red;
    list-style-type: none;
}

body {
    background-color:rgb(255, 234, 206);
    /* background-color: rgb(210, 233, 255); */
}

button {
    cursor: pointer;
}

@media screen and (min-width: 1920px) {
    #root{
        margin: 0px 20rem;
    }
}
@media screen and (max-width: 860px) {
    #root{
        margin: 0px;
    }
}
