.product_card_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin-top: 120px;
    gap: 1rem;
}

.product_card {
    width: 23%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: rgba(211, 211, 211, 0.059);
    height: 100%;
}

.Home_NavLink{
    display:flex;
    flex-direction: column;
    justify-content: center;
    height: 250px;
    object-fit: cover;
    width: 100%;
    text-decoration: none;
}

.product_card:hover {
    box-shadow: 1px 2px 15px 5px gray;
}

.product_img{
    border-radius: 10px;
    box-sizing: border-box;
    height: 200px;
    width: 100%;
    object-fit: cover;
    background-color: white;
    padding: 5px;
}

.product_price_container{
    display: contents;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    position: relative;
    width: 20%;
    left: 35px;
    bottom: 50px;
    padding: 0px 1rem;
    background-color: rgb(77, 76, 76);
    border-radius: 5px;
    text-decoration: none;
    color: white;
    font-size: large;
}

.background_color_behind_images{
    border-top: 2px solid rgba(146, 146, 146, 0.561);
    background-color: rgba(253, 157, 72, 0.617);
    position: absolute;
    top: 108px;
    left: 0;
    right: 0;
    height: 350px;
    z-index: -1;
}

.welcome-back-prompt {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
}
.welcome-back-name {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    text-decoration: underline;
}

.welcome-back-name:hover{
    cursor: pointer;
}

.welcome_back_prompt{
    display: flex;
    justify-content: center;
    gap: 5px;
    color:black;
}
.welcome-back-prompt-no-name {
    text-align: center;
    font-size: 3rem;
}
@media screen and (max-width: 860px) {
    .product_card_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .product_card {
        position: relative;
        bottom: 350px;
        width: 60%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        box-sizing: border-box;
        border-radius: 10px;
        background-color: rgba(211, 211, 211, 0.059);
        height: 100%;
    }
    .product_img{
        border-radius: 10px;
        box-sizing: border-box;
        height: 200px;
        width: 100%;
        object-fit: cover;
        background-color: white;
    }
    .background_color_behind_images{
        border-top: 2px solid rgba(146, 146, 146, 0.561);
        background-color: rgba(253, 157, 72, 0.617);
        position: relative;
        top: 5px;
        left: 0;
        right: 0;
        height: 350px;
        z-index: -1;
    }
    .welcome-back-prompt {
        position: absolute;
        top: 0px;
        font-size: 30px;
        font-weight: 500;
    }
    .welcome-back-prompt-no-name{
        text-align: center;
        font-size: 30px;
        font-weight: 500;
        /* position: absolute;
        top: 0px;
        left: 80px;
        */
    }
    .welcome-back-name {
        position: absolute;
        top: 25px;
        text-align: center;
        font-size: 30px;
        font-weight: 500;
        text-decoration: underline;
    }
}
