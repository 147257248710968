
@media screen and (max-width: 860px) {
    .search-container{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .userCartHeading{
        margin-bottom: 15rem;
    }

}
