.socials{
    text-align: center;
    font-size: large;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0px 20px;
}
.portfolio{
    text-decoration: none;
    color: black;
    padding-bottom: 1rem;
}
.socials > p {
    font-weight: bold;
    font-size: larger;
}
.Socials-icon {
    display: flex;
    justify-content: space-between;
    width: 100px;
    padding-bottom: 15px;
}

.Socials-icon > a {
    color: black;
}

.aboutSocial {
    width: 100%
}
.software_engineer {
    margin-top: 20px;
    width: 200px;
}
